@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: white;

}
html {
    font-family: "Poppins", sans-serif;

}

@layer base {
    li {
        @apply p-4
    }

}
